import React from "react";

import Layout from "../components/layout";
import ChevronLink from '../components/chevronlink';
import SEO from "../components/seo";
import TripleImage from '../components/tripleimage';
import { Link } from "gatsby";

function SuicidalThoughts() {
  return (
      <Layout>
        <SEO 
        title="Suicidal Thoughts"
        description="For those who have been having thoughts of suicide and would like help"
         />
        <div>
          <div className="container mx-auto px-2 md:py-24">
          <Link to="/"><div className="font-bold uppercase text-xs underline mb-4">Back home</div></Link>
            <div className="flex flex-col md:flex-row items-center md:-mx-2">
              <div className="w-full md:w-4/12 px-2">
                <h2 className="text-3xl py-2 lg:pr-24 leading-tight">I've been having thoughts of suicide and would like help</h2>
                <p className="text-primary py-2 lg:pr-12 leading-relaxed">Sharing your feelings of suicide can be very difficult. You may be worried how a person will respond or what they will think of you.</p>
                <p className="text-primary py-2 lg:pr-12 leading-relaxed">Choosing the right person to speak to and starting the conversation is important and can help you to overcome feelings of distress and suicide. </p>
                <p className="text-primary py-2 lg:pr-12 leading-relaxed">There is information to help, people you can speak to and services to support you. Read on to learn more.</p>
              </div>
              <div className="w-full md:w-8/12 px-2 order-first md:order-none">
              <div className="p-4">
                <TripleImage 
                  image1="2.jpg"
                  image2="3.jpg"
                  image3="4.jpg"
                  mode={1}
                />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container px-2  mx-auto py-12">
            <div className="flex flex-col md:flex-row md:-mx-2">
              <div className="w-full md:w-4/12 px-2">
              <div className="bg-box1 h-full p-6 rounded">
                <h3 className="text-2xl py-2 font-bold">Something to read</h3>
                <p className="text-primary py-2 leading-relaxed">For tips on how to <span className="font-bold">start the conversation</span> and <span className="font-bold">keep safe</span>, <a target="_blank" rel="noopener noreferrer" href="https://www.beyondblue.org.au/the-facts/suicide-prevention/feeling-suicidal/talking-to-someone-about-your-suicidal-feelings" className="underline">click&nbsp;here.</a></p>
                <p className="text-primary py-2 leading-relaxed">For help in working out <span className="font-bold">who to talk to</span> and <span className="font-bold">what to say</span>, <a target="_blank" rel="noopener noreferrer" href="https://www.suicidecallbackservice.org.au/resource/talking-about-suicide/" className="underline">click&nbsp;here.</a></p>
                </div>
              </div>
              <div className="w-full md:w-4/12 px-2">
              <div className="bg-box2 h-full p-6 rounded">
                <h3 className="text-2xl py-2 font-bold">Speak to someone</h3>
                <p className="text-primary py-2 leading-relaxed">Talk Suicide provides support to individuals, families and friends of people at risk of suicide, aged 15 years and over, in the Brisbane region. Work with a Support Coach to develop the skills to manage suicidal thoughts and future challenges. Call Talk Suicide on <a className="whitespace-no-wrap underline" href="tel:1800008255">1800 008 255</a>. Talk Suicide operates Monday to Friday 9.00am – 4.30pm and is not a crisis service.</p>
                <p className="text-primary py-2 leading-relaxed">Accessing support through a <span className="font-bold">helpline</span> may also be a good option. A list of helplines you can contact is <a target="_blank" rel="noopener noreferrer" href="https://www.mymentalhealth.org.au/page/services/community-services/sp-care-pathways/self-guided-support/helplines/" className="underline">available here.</a></p>
                <p className="text-primary py-2 leading-relaxed">You may also wish to speak to a GP. A GP can make a diagnosis, discuss suitable treatment options and refer you to a mental health professional if needed. You can search for a General Practice near you by <a target="_blank" rel="noopener noreferrer" href="https://www.mymentalhealth.org.au/page/services/general-practice-and-allied-health/general-practice/" className="underline">clicking&nbsp;here.</a></p>
                </div>
              </div>
              <div className="w-full md:w-4/12 px-2">
              <div className="bg-box3 h-full p-6 rounded">
                <h3 className="text-2xl py-2 font-bold">Access professional help</h3>
                <p className="text-primary py-2 leading-relaxed">If you wish to seek <span className="font-bold">professional, one-on-one help,</span> a range of mental health services are available in the Brisbane North region. </p>
                <p className="text-primary py-2 leading-relaxed">For help in finding the service and supports that will best meet your needs, please contact the My Mental Health Service Navigators by calling <a className="whitespace-no-wrap underline" href="tel:1800752235">1800 752 235</a>. </p>
                <p className="text-primary py-2 leading-relaxed">Please note, this is not a crisis service and operates 8.30am - 4.30pm Mon to Fri.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section >
          <div className="container mx-auto">
            <div className="flex flex-col items-center p-4 md:pt-8 md:pb-16">
              <ChevronLink to="/worried">I'm worried about someone at risk of suicide and need to know how to help</ChevronLink>
          </div>
          </div>
      </section>
      </Layout>
  );
}

export default SuicidalThoughts;
